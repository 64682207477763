<template>
    <a-drawer
        :visible="visible"
        @close="onClose"
        class="task_edit"
        :class="{ mobile_task_edit: isMobile }"
        :width="drawerWidth"
        :zIndex="taskDrawerZIndex"
        :afterVisibleChange="afterVisibleChange"
        :title="editDrawerTitle">
        <a-tag
            v-if="isDraft"
            closable
            @close="clearDraftForm"
            class="draft_task"
            color="purple">
            {{ $t("task.draft") }}
        </a-tag>
        <div class="d_body w-full" ref="tFormBody">
            <a-spin class="w-full" :spinning="formLoading">
                <template v-if="formInfo">
                    <a-form-model
                        ref="taskForm"
                        class="task_form_wrap"
                        :model="form"
                        :rules="rules">
                        <a-form-model-item
                            v-if="formInfo.name"
                            :rules="formInfo.name.rules"
                            :label="formInfo.name.title"
                            prop="name">
                            <a-input
                                v-model="form.name"
                                size="large"
                                :placeholder="formInfo.name.title"/>
                        </a-form-model-item>
                        <a-form-model-item
                            v-if="!edit && formInfo.task_type"
                            :label="formInfo.task_type.title"
                            :rules="formInfo.task_type.rules"
                            prop="task_type">
                            <a-select
                                v-model="form.task_type"
                                size="large"
                                :getPopupContainer="getPopupContainer"
                                :placeholder="$t('task.task_type')"
                                defaultValue="task">
                                <a-select-option
                                    v-for="item in taskTypeOptions"
                                    :key="item.value"
                                    :value="item.value">
                                    {{ item.label }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                        <a-form-model-item
                            v-if="formInfo.description"
                            :label="formInfo.description.title"
                            :rules="formInfo.description.rules"
                            prop="description">
                            <component
                                :is="ckEditor"
                                :taskId="form.id"
                                :key="edit || visible"
                                v-model="form.description"/>
                        </a-form-model-item>
                        <a-form-model-item
                            v-if="formInfo.result"
                            :rules="formInfo.result.rules"
                            :label="formInfo.result.title"
                            prop="result">
                            <a-input
                                v-model="form.result"
                                size="large"
                                :placeholder="formInfo.result.title"/>
                        </a-form-model-item>
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <a-form-model-item
                                v-if="formInfo.date_start_plan"
                                :rules="rules.date_start_plan"
                                :label="formInfo.date_start_plan.title"
                                prop="date_start_plan">
                                <DatePicker
                                    v-model="form.date_start_plan"
                                    size="large"
                                    :getCalendarContainer="getPopupContainer"
                                    :rangeLimit="
                                        form.s_dead_line_from ? form.s_dead_line_from : ''
                                    "
                                    :dateLimitFrom="
                                        form.dead_line
                                            ? form.dead_line
                                            : '' || form.p_dead_line_from
                                                ? form.p_dead_line_from
                                                : ''
                                    "
                                    :disabled="isMilestone"
                                    :show-time="{ format: 'HH:mm' }"
                                    @change="dateStartChange"/>
                            </a-form-model-item>
                            <a-form-model-item
                                v-if="formInfo.dead_line"
                                :rules="rules.dead_line"
                                :label="formInfo.dead_line.title"
                                prop="dead_line">
                                <DatePicker
                                    v-model="form.dead_line"
                                    :rangeLimitDeadLine="
                                        form.r_dead_line_from ? form.r_dead_line_from : ''
                                    "
                                    :planLimit="
                                        form.date_start_plan
                                            ? form.date_start_plan
                                            : '' || form.s_dead_line_from
                                                ? form.s_dead_line_from
                                                : ''
                                    "
                                    :dateLimit="
                                        form.p_dead_line
                                            ? form.p_dead_line
                                            : '' || form.date_start_plan
                                                ? form.date_start_plan
                                                : ''
                                    "
                                    :dateLimitFrom="
                                        form.p_dead_line_from ? form.p_dead_line_from : ''
                                    "
                                    size="large"
                                    :getCalendarContainer="getPopupContainer"
                                    :startTime="false"
                                    @change="deadlineChange"
                                    :show-time="{ format: 'HH:mm' }"/>
                            </a-form-model-item>
                        </div>
                        <div class="flex">
                            <a-button
                                v-for="tab in tabs"
                                :key="tab.value"
                                size="large"
                                class="mr-4 last:mr-0 button-light"
                                :class="invalidFields.includes('organization') && tab.value === 'select_project' && 'button_error'"
                                block
                                :type="activeTab === tab.value ? 'primary' : ''"
                                @click="selectTab(tab.value)">
                                {{ tab.label }}
                            </a-button>
                        </div>
                        <div v-show="activeTab">
                            <div class="mt-5">
                                <div class="tab-panel">
                                    <div v-show="activeTab === 'add_user'">
                                        <div class="flex">
                                            <a-button
                                                v-for="tab in userTypeTab"
                                                :key="tab.value"
                                                size="large"
                                                class="mr-2.5 last:mr-0 button-gray"
                                                :class="activeUserTab === tab.value && 'active'"
                                                block
                                                @click="selectUserTab(tab.value)">
                                                {{ tab.label }}
                                            </a-button>
                                        </div>
                                        <div v-show="activeUserTab === 'operator'">
                                            <div class="pt-5">
                                                <a-form-model-item
                                                    v-if="formInfo.operator"
                                                    :rules="formInfo.operator.rules"
                                                    :label="formInfo.operator.title"
                                                    prop="operator"
                                                    class="mb-0">
                                                    <div>
                                                        <UserDrawer
                                                            v-model="form.operator"
                                                            :taskId="form.id ? form.id : null"
                                                            :id="form.id || defaultUserSelectId"
                                                            inputSize="large"
                                                            class="w-full"
                                                            :disabled="form.is_auction"
                                                            :filters="
                                                                formInfo.operator.filters
                                                                    ? formInfo.operator.filters
                                                                    : null
                                                            "
                                                            :oldSelected="checkOldSelect(formInfo.operator)"
                                                            :title="
                                                                formInfo.operator.drawerTitle ||
                                                                    $t('task.select_performer')
                                                            "/>
                                                    </div>
                                                    <a-checkbox
                                                        v-if="formInfo.operator.auction"
                                                        v-model="form.is_auction"
                                                        @click="selectAuction()">
                                                        {{ $t('Activate auction') }}
                                                    </a-checkbox>
                                                </a-form-model-item>
                                            </div>
                                        </div>
    
                                        <div v-show="activeUserTab === 'owner'">
                                            <div class="pt-5">
                                                <a-form-model-item
                                                    v-if="!edit && formInfo.owner"
                                                    :rules="formInfo.owner.rules"
                                                    :label="formInfo.owner.title"
                                                    class="mb-0"
                                                    prop="owner">
                                                    <UserDrawer
                                                        :id="form.id || defaultUserSelectId"
                                                        v-model="form.owner"
                                                        :taskId="form.id ? form.id : null"
                                                        inputSize="large"
                                                        :title="
                                                            formInfo.owner.drawerTitle ||
                                                                $t('task.select_author')
                                                        "/>
                                                </a-form-model-item>
                                            </div>
                                        </div>
    
                                        <div v-show="activeUserTab === 'visors'">
                                            <div class="pt-5">
                                                <a-form-model-item
                                                    v-if="formInfo.visors"
                                                    :rules="formInfo.visors.rules"
                                                    :label="formInfo.visors.title"
                                                    class="mb-0"
                                                    prop="visors">
                                                    <UserDrawer
                                                        :id="form.id || defaultUserSelectId"
                                                        :metadata="{ key: 'visors', value: form.metadata }"
                                                        :changeMetadata="changeMetadata"
                                                        v-model="form.visors"
                                                        :taskId="form.id ? form.id : null"
                                                        multiple
                                                        inputSize="large"
                                                        :title="
                                                            formInfo.visors.drawerTitle ||
                                                                $t('task.select_observers')
                                                        "/>
                                                </a-form-model-item>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-show="activeTab === 'select_project'">
                                        <a-form-model-item
                                            v-if="formInfo.organization"
                                            :rules="formInfo.organization.rules"
                                            :label="formInfo.organization.title"
                                            prop="organization">
                                            <OrganizationsDrawer
                                                v-model="form.organization"
                                                :defaultActiveFirstOption="!edit"
                                                :title="
                                                    formInfo.organization.drawerTitle ||
                                                        $t('task.select_organization')
                                                "
                                                inputSize="large"/>
                                        </a-form-model-item>
    
                                        <a-form-model-item
                                            v-if="formInfo.project"
                                            :rules="formInfo.project.rules"
                                            :label="formInfo.project.title"
                                            prop="project">
                                            <ProjectDrawer
                                                v-model="form.project"
                                                :selectProject="selectProject"
                                                :title="
                                                    formInfo.project.drawerTitle ||
                                                        $t('task.select_project')
                                                "
                                                inputSize="large"/>
                                        </a-form-model-item>
    
                                        <a-form-model-item
                                            v-if="formInfo.parent"
                                            :rules="formInfo.parent.rules"
                                            :label="formInfo.parent.title"
                                            prop="parent">
                                            <div
                                                class="popover_input ant-input flex items-center relative ant-input-lg truncate"
                                                :class="changeParentDisabled && 'ant-input-disabled'">
                                                <a-tooltip
                                                    v-if="form.parent"
                                                    :title="form.parent.name"
                                                    class="mr-2 truncate">
                                                    <a-tag
                                                        color="blue"
                                                        class="tag_block truncate"
                                                        @click="openSubtaskSelection">
                                                        {{ form.parent.name }}
                                                    </a-tag>
                                                </a-tooltip>
                                                <a-button
                                                    @click="openSubtaskSelection"
                                                    type="link"
                                                    :icon="!form.parent && 'plus'"
                                                    class="px-0">
                                                    {{
                                                        form.parent ? $t("task.change") : $t("task.select")
                                                    }}
                                                </a-button>
                                                <a-button
                                                    v-if="form.parent"
                                                    @click="selectParentTask()"
                                                    type="link"
                                                    icon="close-circle"
                                                    class="px-0 text-current remove_parent"/>
                                            </div>
                                        </a-form-model-item>
    
                                        <a-form-model-item
                                            v-if="formInfo.workgroup"
                                            :rules="formInfo.workgroup.rules"
                                            :label="formInfo.workgroup.title"
                                            class="mb-0"
                                            prop="workgroup">
                                            <WorkGroupDrawer
                                                :key="isHelpdesk"
                                                :title="
                                                    formInfo.workgroup.drawerTitle ||
                                                        $t('task.subject_matter')
                                                "
                                                v-model="form.workgroup"
                                                inputSize="large"/>
                                        </a-form-model-item>
                                    </div>
                                    <div v-show="activeTab === 'set_priority'">
                                        <a-form-model-item
                                            v-if="formInfo.priority"
                                            :rules="formInfo.priority.rules"
                                            class="mb-0"
                                            prop="priority">
                                            <Priority v-model="form.priority" :key="edit || visible" />
                                        </a-form-model-item>
                                    </div>
                                    <div v-show="activeTab === 'attach_files'">
                                        <a-form-model-item
                                            v-if="formInfo.files"
                                            :rules="formInfo.files.rules"
                                            class="mb-0"
                                            prop="attachments">
                                            <a-button
                                                type="link"
                                                size="small"
                                                class="p-0"
                                                @click="openFileModal">
                                                + {{ $t("Attach files") }}
                                            </a-button>
                                            <div v-show="form.attachments.length">
                                                <p>{{ $t("Attached files") }}</p>
                                                <FileAttach
                                                    ref="fileAttach"
                                                    :zIndex="1100"
                                                    :attachmentFiles="form.attachments"
                                                    :maxMBSize="50"
                                                    createFounder
                                                    listType="picture"
                                                    :showDeviceUpload="true"/>
                                            </div>
                                            <!-- <FileDrawer
                                                ref="fileDrawer" 
                                                :attachmentFiles="form.attachments"
                                                :rootId="form.id"/>
                                             -->
                                            <!-- <a-button 
                                                @click="openFileDrawer"
                                                class="flex justify-center items-center mb-2">
                                                <i class="fi fi-rr-download mr-2"></i>
                                                <span>{{ $t('task.upload_file') }}</span>
                                            </a-button> -->
                                        </a-form-model-item>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <a-form-model-item
                            v-if="formInfo.potential_contractor_name"
                            :rules="formInfo.potential_contractor_name.rules"
                            :label="formInfo.potential_contractor_name.title"
                            :label-col="
                                formInfo.potential_contractor_name['label-col'] || { span: 5 }
                            "
                            :wrapper-col="
                                formInfo.potential_contractor_name['wrapper-col'] || {
                                    span: 12,
                                }
                            "
                            labelAlign="left"
                            ref="p_contractor_name"
                            prop="p_contractor_name">
                            <a-input
                                v-model="form.p_contractor_name"
                                :disabled="form.contractor !== null"
                                size="large"
                                allowClear
                                :placeholder="formInfo.potential_contractor_name.title || null"/>
                        </a-form-model-item>
                        <a-form-model-item
                            v-if="formInfo.potential_contractor_company"
                            :rules="formInfo.potential_contractor_company.rules || null"
                            :label="formInfo.potential_contractor_company.title"
                            :label-col="
                                formInfo.potential_contractor_company['label-col'] || {
                                    span: 5,
                                }
                            "
                            :wrapper-col="
                                formInfo.potential_contractor_company['wrapper-col'] || {
                                    span: 12,
                                }
                            "
                            labelAlign="left"
                            ref="p_contractor_company"
                            prop="p_contractor_company">
                            <a-input
                                v-model="form.p_contractor_company"
                                :disabled="form.contractor !== null"
                                size="large"
                                allowClear
                                :placeholder="
                                    formInfo.potential_contractor_company.title || null
                                "/>
                        </a-form-model-item>
                        <a-form-model-item
                            v-if="formInfo.phone"
                            :label="formInfo.phone.title"
                            :label-col="formInfo.phone['label-col'] || { span: 5 }"
                            :wrapper-col="formInfo.phone['wrapper-col'] || { span: 12 }"
                            labelAlign="left"
                            ref="phone"
                            prop="phone">
                            <a-input
                                v-model="form.phone"
                                :disabled="form.contractor !== null"
                                size="large"
                                allowClear
                                :placeholder="formInfo.phone.title || null"/>
                        </a-form-model-item>
                        <a-form-model-item
                            v-if="formInfo.email"
                            :rules="formInfo.email.rules || null"
                            :label="formInfo.email.title"
                            :label-col="formInfo.email['label-col'] || { span: 5 }"
                            :wrapper-col="formInfo.email['wrapper-col'] || { span: 12 }"
                            labelAlign="left"
                            ref="email"
                            prop="email">
                            <a-input
                                v-model="form.email"
                                :disabled="form.contractor !== null"
                                size="large"
                                allowClear
                                :placeholder="formInfo.email.title || null"/>
                        </a-form-model-item>
                        <a-form-model-item
                            v-if="formInfo.lead_source"
                            :rules="formInfo.lead_source.rules || null"
                            :label="formInfo.lead_source.title"
                            :label-col="formInfo.lead_source['label-col'] || { span: 5 }"
                            :wrapper-col="formInfo.lead_source['wrapper-col'] || { span: 12 }"
                            labelAlign="left"
                            ref="lead_source"
                            prop="lead_source">
                            <a-select
                                v-model="form.lead_source"
                                allowClear
                                size="large"
                                :getPopupContainer="getPopupContainer"
                                :loading="leadSourcesLoader"
                                @change="onChange"
                                :placeholder="$t('task.source_appeal')">
                                <a-select-option
                                    v-for="lead_source in leadSources"
                                    :key="lead_source.id"
                                    :value="lead_source.id">
                                    {{ lead_source.name }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                        <a-form-model-item
                            v-if="isFromOrder()"
                            :rules="formInfo.start_point.rules || null"
                            :label="formInfo.start_point.title"
                            prop="start_point"
                            :label-col="formInfo.start_point['label-col'] || { span: 5 }"
                            :wrapper-col="formInfo.start_point['wrapper-col'] || { span: 12 }"
                            labelAlign="left">
                            <a-select v-model="form.start_point">
                                <a-select-option
                                    v-for="startPoint in startPointList"
                                    :key="startPoint.id"
                                    :value="startPoint.id">
                                    {{ startPoint.string_view }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                        <a-form-model-item
                            v-if="formInfo.contractor"
                            :rules="formInfo.contractor.rules"
                            :label="formInfo.contractor.title"
                            :label-col="formInfo.contractor['label-col'] || { span: 5 }"
                            :wrapper-col="formInfo.contractor['wrapper-col'] || { span: 12 }"
                            labelAlign="left"
                            ref="contractor"
                            prop="contractor">
                            <ContractorDrawer
                                v-model="form.contractor"
                                inputSize="large"
                                :selectContractor="contractorHandler"
                                :title="
                                    formInfo.contractor.drawerTitle ||
                                        $t('task.contractordrawer.drawerTitle')
                                "/>
                        </a-form-model-item>

                        <a-collapse
                            v-model="expandActive"
                            :bordered="false"
                            class="collapse mt-4">
                            <a-collapse-panel
                                key="1"
                                :header="$t('task.additionally')">
                                <a-form-model-item 
                                    v-if="formInfo.funds"
                                    :label="formInfo.funds.title"
                                    prop="funds">
                                    <a-input-number
                                        v-model="form.funds"
                                        :max="Math.pow(10, 13)-1"
                                        :min="0"
                                        :placeholder="formInfo.funds.title"
                                        class="w-full"
                                        size="large"/>
                                </a-form-model-item>
                                <a-form-model-item 
                                    v-if="formInfo.execution_time_plan"
                                    :label="formInfo.execution_time_plan.title"
                                    prop="execution_time_plan">
                                    <a-input-number
                                        v-model="form.execution_time_plan"
                                        :max="Math.pow(10, 4)-1"
                                        :min="0"
                                        :placeholder="formInfo.execution_time_plan.title"
                                        class="w-full"
                                        size="large"/>
                                </a-form-model-item>

                                <a-form-model-item
                                    v-if="!edit"
                                    prop="makeEvent">
                                    <a-checkbox v-model="form.is_need_to_make_event">
                                        {{ $t('task.make_event') }}
                                    </a-checkbox>
                                </a-form-model-item>
                                <a-form-model-item
                                    v-if="form.reason_model"
                                    :label="$t('task.task_based')"
                                    :label-col="{ span: 6 }"
                                    :wrapper-col="{ span: 13 }"
                                    labelAlign="left">
                                    <div
                                        class="user_draw_input ant-input flex items-center relative">
                                        <a-tag color="blue" class="tag_block">
                                            <template v-if="form.reason_model === 'chat_message'">
                                                {{ $t("task.chat_message") }}
                                            </template>
                                            <template v-if="form.reason_model === 'comments'">
                                                {{ $t("task.comment") }}
                                            </template>
                                            <template v-if="form.reason_model === 'files'">
                                                {{ $t("task.file2") }}
                                            </template>
                                            <template v-if="form.reason_model === 'order'">
                                                {{ $t("task.order") }} №{{ form.reason_name }}
                                            </template>
                                        </a-tag>
                                        <a-button @click="reasonClear()" type="link" class="px-0">
                                            {{ $t("task.remove") }}
                                        </a-button>
                                    </div>
                                </a-form-model-item>

                                <a-button
                                    v-if="formInfo.set_points && formInfo.set_points.available"
                                    @click="openAddressDrawer"
                                    :loading="addressButtonLoading">
                                    {{
                                        formInfo.set_points.button_text ||
                                            $t("task.specify_address")
                                    }}
                                </a-button>
                                <div v-if="taskPointsList.length !== 0" class="mt-5">
                                    <a-descriptions :title="mapConfig.listTitle" />

                                    <div class="divide-y">
                                        <a-list
                                            item-layout="horizontal"
                                            v-for="(point, n) in taskPointsList"
                                            :key="n">
                                            <a-list-item>
                                                <a-list-item-meta>
                                                    <div slot="title" class="flex flex-row">
                                                        <div class="mr-5">{{ n + 1 }}</div>
                                                        <div>{{ point.name }}</div>
                                                    </div>
                                                </a-list-item-meta>
                                            </a-list-item>
                                        </a-list>
                                    </div>
                                </div>
                            </a-collapse-panel>
                        </a-collapse>
                    </a-form-model>
                </template>
            </a-spin>
        </div>
        <div v-if="formInfo" class="d_footer">
            <a-button
                v-if="edit"
                type="primary"
                :block="isMobile"
                size="large"
                class="mr-2 w-full md:w-auto lg:w-auto"
                :loading="loading"
                @click="update()">
                {{ $t("Save") }}
            </a-button>
            <template v-else>
                <div v-if="isMobile" class="flex items-center w-full">
                    <a-button
                        type="primary"
                        :loading="loading"
                        block
                        size="large"
                        @click="submit()">
                        {{ $t("task.save") }}
                    </a-button>
                    <a-button
                        type="primary"
                        :disabled="loading"
                        size="large"
                        class="ml-1 dots_btn"
                        flaticon
                        icon="fi-rr-menu-dots-vertical"
                        @click="visibleActivity = true"/>
                    <ActivityDrawer v-model="visibleActivity">
                        <ActivityItem
                            v-if="formInfo.formActions.create_and_open"
                            key="0"
                            @click="submitAndOpen()">
                            <i class="fi fi-rr-link-horizontal"></i>
                            {{ $t("task.create_and_open") }}
                        </ActivityItem>
                        <ActivityItem
                            v-if="formInfo.formActions.create_and_create"
                            key="1"
                            @click="submitAndCreate()">
                            <i class="fi fi-rr-apps-add"></i>
                            {{ $t("task.create_and_create") }}
                        </ActivityItem>
                        <ActivityItem
                            v-if="formInfo.formActions.create_and_copy"
                            key="2"
                            @click="submitAndCopy()">
                            <i class="fi fi-rr-duplicate"></i>
                            {{ $t("task.create_and_copy") }}
                        </ActivityItem>
                    </ActivityDrawer>
                </div>
                <a-button-group v-else class="mr-2">
                    <a-button type="primary" :loading="loading" @click="submit()">
                        {{ $t("task.save") }}
                    </a-button>
                    <a-dropdown
                        v-if="formInfo.formActions"
                        :trigger="['click', 'hover']"
                        :disabled="loading">
                        <a-button type="primary" icon="more" />
                        <a-menu slot="overlay">
                            <a-menu-item
                                v-if="formInfo.formActions.create_and_open"
                                key="0"
                                @click="submitAndOpen()">
                                {{ $t("task.create_and_open") }}
                            </a-menu-item>
                            <a-menu-item
                                v-if="formInfo.formActions.create_and_create"
                                key="1"
                                @click="submitAndCreate()">
                                {{ $t("task.create_and_create") }}
                            </a-menu-item>
                            <a-menu-item
                                v-if="formInfo.formActions.create_and_copy"
                                key="2"
                                @click="submitAndCopy()">
                                {{ $t("task.create_and_copy") }}
                            </a-menu-item>
                        </a-menu>
                    </a-dropdown>
                </a-button-group>
            </template>
            <a-button v-if="!isMobile" type="ui" ghost @click="onClose()">
                {{ $t("task.close") }}
            </a-button>
        </div>
        <TaskSelectDrawer
            v-model="form.parent"
            :taskDrawer="taskDrawer"
            :selectParentTask="selectParentTask"
            :filters="taskSelectFilters"
            :closeHandler="closeTaskSelected"/>
        <a-drawer
            :title="addressDrawerTitle"
            :width="windowWidth > 1500 ? 1500 : windowWidth"
            :destroyOnClose="true"
            :visible="addressDrawerVisible"
            class="set_task_points"
            @close="onCloseAddressDrawer"
            :zIndex="taskDrawerZIndex + 10">
            <SetPoints
                ref="setPointRef"
                :mapConfig="mapConfig"
                :taskTitle="form.name"
                :ownerSelect="true"/>
            <div class="d_footer flex justify-end">
                <a-button
                    type="ui"
                    ghost
                    block
                    class="my-2 w-24"
                    @click="onCloseAddressDrawer">
                    {{ $t("task.close") }}
                </a-button>
            </div>
        </a-drawer>
    </a-drawer>
</template>

<script>
import { mapState } from "vuex";
import { priorityList } from "@/utils/localeData";
// import UserDrawer from '../UserDrawer'
import UserDrawer from "@apps/DrawerSelect/index.vue";
import ContractorDrawer from "../ContractorDrawer";
import Upload from "@apps/Upload";
import FileAttach from "@apps/vue2Files/components/FileAttach";
import Priority from "../Priority.vue";
import ProjectDrawer from "../ProjectDrawer.vue";
import OrganizationsDrawer from "../OrganizationsDrawer.vue";
import WorkGroupDrawer from "../WorkGroupDrawer.vue";
import TaskSelectDrawer from "./TaskSelectDrawer.vue";
import eventBus from "@/utils/eventBus";
import DatePicker from "./DatePick";
import SetPoints from "./SetPoints";
import { ActivityItem, ActivityDrawer } from "@/components/ActivitySelect";
import { isEqual } from "lodash";
import FileDrawer from "../FileDrawer.vue";
import DSelect from "@apps/DrawerSelect/Select.vue";

import Vue from "vue";

let formWatch;
const formModel = {
    metadata: {
        visors: [],
    },
    organization: null,
    parent: null,
    project: null,
    workgroup: null,
    operator: null,
    owner: null,
    visors: [],
    prerequisites: [],
    attachments: [],
    dead_line: null,
    name: "",
    description: "",
    priority: 2,
    is_indefinite: false,
    date_start_plan: null,
    reason: null,
    is_auction: false,
    task_type: "task",
    tmp_phone: null,
    contractor: null,
    p_contractor_name: "",
    p_contractor_company: "",
    phone: "",
    email: "",
    lead_source: null,
    is_need_to_make_event: false,
};
const checkDate = (data, type) => {
    if (data[type]) {
        return data[type];
    } else if (data.project?.[type]) {
        return data.project[type];
    }

    return null;
};
const checkDateDeadLine = (data, type) => {
    if (data.project?.[type]) {
        return data.project[type];
    } else if (data[type]) {
        return data[type];
    }

    return null;
};

export default {
    name: "TaskEditDrawer",
    components: {
        UserDrawer,
        Priority,
        // eslint-disable-next-line vue/no-unused-components
        Upload,
        // eslint-disable-next-line vue/no-unused-components
        FileAttach,
        DatePicker,
        ProjectDrawer,
        OrganizationsDrawer,
        WorkGroupDrawer,
        TaskSelectDrawer,
        ContractorDrawer,
        SetPoints,
        ActivityItem,
        ActivityDrawer,
        FileDrawer,
        DSelect,
    },
    computed: {
        ...mapState({
            pageName: (state) => state.task.pageName,
            windowWidth: (state) => state.windowWidth,
            user: (state) => state.user.user,
            formDefault: (state) => state.task.formDefault,
            taskList: (state) => state.task.taskList,
            task: (state) => state.task.task,
            mainKey: (state) => state.task.mainKey,
            formInfoData: (state) => state.task.formInfo,
            taskDrawerZIndex: (state) => state.task.taskDrawerZIndex,
            taskPointsList: (state) => state.task.taskPointsList,
            mapConfig: (state) => state.task.mapConfig,
            leadSources: (state) => state.task.leadSources,
            leadSourcesLoader: (state) => state.task.leadSourcesLoader,
        }),
        addressDrawerTitle() {
            return this.formInfo?.set_points?.drawer_title
                ? this.formInfo.set_points.drawer_title
                : this.$t("task.specify_address_2");
        },
        openButtonRef() {
            if (this.$refs.openFileTask?.length) return this.$refs.openFileTask;
            return false;
        },
        drawerWidth() {
            if (this.windowWidth > 900) return 900;
            else if (this.windowWidth < 800 && this.windowWidth > 500)
                return this.windowWidth - 30;
            else return this.windowWidth;
        },
        visible: {
            get() {
                return this.$store.state.task.editDrawer;
            },
            set(value) {
                this.$store.commit("task/SET_EDIT_DRAWER", value);
            },
        },
        ckEditor() {
            if (this.visible) return () => import("@apps/CKEditor");
            else return null;
        },
        filtersUserDrawer() {
            if (this.form?.contractor) {
                return { contractor_profile__contractor: this.form.contractor?.id };
            }
            return null;
        },
        taskSelectFilters() {
            let filters = {};
            if (this.form.workgroup) {
                filters.or_workgroup = this.form.workgroup.id;
            }
            if (this.form.project) {
                filters.or_project = this.form.project.id;
            }
            return filters;
        },
        isMobile() {
            return this.$store.state.isMobile;
        },
        taskType() {
            return this.$store.state.task.taskType;
        },
        showInputPhone() {
            return this.isHelpdesk;
        },
        isHelpdesk() {
            return this.taskType === "helpdesk";
        },
        editDrawerTitle() {
            if (this.formInfo) {
                if (this.isHelpdesk)
                    return this.edit
                        ? this.$t("task.edit_appeal")
                        : this.$t("task.add_appeal");
                return this.edit
                    ? this.formInfo.drawereditTitle || this.$t("task.edit_task")
                    : this.formInfo.drawerTitle || this.$t("task.add_task");
            } else return "";
        },
        // namePlaceholder() {
        //     if(this.isHelpdesk)
        //         return this.$t('task.appeal_name')
        //     return this.$t('task.task_name')
        // },
        rules() {
            const rules = {};
            if (this.showInputPhone) {
                rules.tmp_phone = [
                    {
                        required: true,
                        message: this.$t("task.field_require"),
                        trigger: "blur",
                    },
                    {
                        max: 255,
                        message: this.$t("task.field_min_require"),
                        trigger: "blur",
                    },
                ];
                rules.workgroup = [
                    {
                        required: true,
                        message: this.$t("task.field_require"),
                        trigger: "blur",
                    },
                ];
            }

            rules.dead_line = this.formInfo?.dead_line?.rules || [
                {
                    required: false,
                    message: this.$t("task.field_require"),
                    trigger: "blur",
                },
            ];
            rules.date_start_plan = this.formInfo?.date_start_plan?.rules || [
                {
                    required: false,
                    message: this.$t("task.field_require"),
                    trigger: "blur",
                },
            ];

            if (this.form.task_type === "stage") {
                rules.dead_line[0].required = true;
                rules.date_start_plan[0].required = true;
            }
            if (this.form.task_type === "milestone") {
                rules.dead_line[0].required = true;
            }

            return rules;
        },
        isMilestone() {
            return this.form.task_type === "milestone";
        },
    },
    data() {
        return {
            invalidFields: [],
            tabs: [
                {
                    value: "add_user",
                    label: this.$t("Add user"),
                },
                {
                    value: "select_project",
                    label: this.$t("Select project"),
                },
                {
                    value: "set_priority",
                    label: this.$t("Set priority"),
                },
                {
                    value: "attach_files",
                    label: this.$t("Attach files"),
                },
            ],
            userTypeTab: [
                {
                    value: "operator",
                    label: this.$t("Operator"),
                },
                // {
                //     value: "cooperator",
                //     label: this.$t("Cooperator"),
                // },
                {
                    value: "owner",
                    label: this.$t("Owner"),
                },
                {
                    value: "visors",
                    label: this.$t("Visors"),
                },
            ],
            activeTab: "add_user",
            activeUserTab: "operator",

            disableDraft: false,
            changeParentDisabled: false,
            formInfo: null,
            formLoading: false,
            taskDrawer: false,
            expandActive: 0,
            edit: false,
            back: false,
            isDraft: false,
            copy: false,
            formInject: null,
            priorityList,
            visibleActivity: false,
            loading: false,
            moreCreate: false,
            formCopy: false,
            subtask: false,
            fileList: [],
            open: false,
            form: Object.assign({}, formModel),
            startPointList: [],
            addressDrawerVisible: false,
            addressButtonLoading: false,
            defaultUserSelectId: "empty_task",
            taskTypeOptions: [
                { value: "stage", label: this.$t("Stage") },
                { value: "task", label: this.$t("Task") },
                { value: "milestone", label: this.$t("Milestone") },
            ],
        };
    },
    watch: {
        visible(val) {
            if (this.$route.query?.viewGroup || this.$route.query?.viewProject)
                this.$store.commit("task/SET_TASK_DRAWER_ZINDEX", 1010);

            if (val) {
                if (
          this.formDefault?.["task_type"] &&
          this.formInfoData?.[this.formDefault["task_type"]]
                ) {
                    if (this.form.task_type !== this.formDefault["task_type"]) {
                        this.form.task_type = this.formDefault["task_type"];
                    }
                    this.formInfo = this.formInfoData[this.formDefault["task_type"]];
                }
            }
        },
    },

    created() {
        setTimeout(() => {
            this.checkOpen();
        }, 600);
    },
    methods: {
        selectTab(value) {
            if (value === this.activeTab) {
                this.activeTab = ''
                return;
            }
            this.activeTab = value;
        },
        selectUserTab(value) {
            // if (value === this.activeTab) {
            //     this.activeUserTab = ''
            //     return;
            // }
            this.activeUserTab = value;
        },
        changeMetadata({ key, value }) {
            Vue.set(this.form.metadata, key, value);
        },
        openFileDrawer() {
            this.$refs.fileDrawer.openDrawer();
        },
        selectAuction() {
            this.form.operator = null;
            this.form.is_auction = !this.form.is_auction;
        },
        contractorHandler(contractor) {
            this.form.p_contractor_name = "";
            this.form.p_contractor_company = "";
            this.form.phone = contractor ? contractor.phone : "";
            this.form.email = contractor ? contractor.email : "";
        },
        onChange(val) {
            if (!val) {
                this.form.lead_source = null;
            }
        },
        getPopupContainer() {
            return this.$refs.tFormBody;
        },
        onClose() {
            this.disableDraft = false;
            this.changeParentDisabled = false;

            this.visible = false;
            this.$store.commit("task/SET_TASK_POINT_LIST", []);
            this.$refs.taskForm.resetFields();
        },
        async openAddressDrawer() {
            await this.$store.dispatch("task/getMapConfig").then(() => {
                this.$nextTick(() => {
                    this.addressDrawerVisible = true;
                });
            });
        },
        onCloseAddressDrawer() {
            this.$nextTick(() => {
                this.addressDrawerVisible = false;
            });
            this.$refs.setPointRef.clearAll();
        },
        checkOldSelect(field) {
            if (typeof field.oldSelected === "boolean") {
                return field.oldSelected;
            } else return true;
        },
        setButtonLoading(value) {
            this.loading = value;
        },
        nameGenerator() {
            if (this.formInfo?.nameGenerate && !this.edit) {
                if (this.form.task_type === "logistic") {
                    this.form.name = `${$t("task.delivery_from")} ${this.$moment().format(
                        "DD.MM.YYYY"
                    )}`;
                }
                if (this.form.task_type === "task") {
                    this.form.name = `${$t("task.task_from")} ${this.$moment().format(
                        "DD.MM.YYYY"
                    )}`;
                }
            }
        },
        async getFormInfo() {
            if (!this.formInfo) {
                try {
                    this.formLoading = true;
                    await this.$store.dispatch("task/getFormInfo", {
                        task_type: this.form.task_type,
                    });
                    this.formInfo = this.$store.getters["task/getFormInfoByType"](
                        this.form.task_type
                    );
                    
                } catch (e) {
                    console.log(e);
                } finally {
                    this.formLoading = false;
                }
            }

            this.nameGenerator();
        },
        selectProject(item) {
            this.form.parent = null;
            if (!item) {
                if (!this.form.parent?.dead_line) {
                    this.form.p_dead_line_from = null;
                    this.form.r_dead_line_from = null;
                }
                if (!this.form.parent?.date_start_plan) {
                    this.form.s_dead_line_from = null;
                }
            } else {
                if (!this.form.parent?.dead_line) {
                    this.form.p_dead_line_from = item.dead_line;
                    this.form.r_dead_line_from = item.dead_line;
                }
                if (!this.form.parent?.date_start_plan) {
                    this.form.s_dead_line_from = item.date_start_plan;
                }
            }
        },
        selectParentTask(item) {
            if (this.changeParentDisabled) {
                return;
            }

            if (!item) {
                if (!this.form.project?.dead_line) {
                    this.form.p_dead_line_from = null;
                    this.form.r_dead_line_from = null;
                }
                if (!this.form.project?.date_start_plan) {
                    this.form.s_dead_line_from = null;
                }
                this.form.parent = null;
            } else {
                let showMessage = false;
                if (
                    this.form.dead_line &&
          item.dead_line &&
          this.$moment(this.form.dead_line).isAfter(item.dead_line)
                ) {
                    this.form.dead_line = this.$moment(this.form.dead_line);
                    showMessage = true;
                }
                if (
                    this.form.date_start_plan &&
          item.date_start_plan &&
          this.$moment(this.form.date_start_plan).isBefore(item.date_start_plan)
                ) {
                    this.form.date_start_plan = this.$moment(item.date_start_plan);
                    showMessage = true;
                }

                if (showMessage) {
                    this.$message.info(this.$t("task.date_change"));
                }

                if (!this.form.project?.dead_line) {
                    this.form.p_dead_line_from = item.dead_line || null;
                    this.form.r_dead_line_from = item.dead_line || null;
                }
                if (!this.form.project?.date_start_plan) {
                    this.form.s_dead_line_from = item.date_start_plan || null;
                }
                this.form.dead_line = item.dead_line
                    ? this.$moment(item.dead_line)
                    : null;
            }
        },
        dateStartChange(date) {
            if (
                this.form.dead_line &&
        this.$moment(this.form.dead_line).isSameOrBefore(date)
            ) {
                this.form.date_start_plan = this.$moment(date).subtract({ hours: 1 });
            }
        },
        deadlineChange(date) {
            if (
                this.form.dead_line &&
        this.form.date_start_plan &&
        this.$moment(this.form.date_start_plan).isSameOrAfter(date)
            ) {
                this.form.date_start_plan = this.$moment(this.form.dead_line).subtract({
                    hours: 1,
                });
            }
            if (
                (this.form.project?.dead_line &&
          this.$moment(this.form.project.dead_line).isSameOrBefore(date)) ||
        (this.form.project?.dead_line &&
          this.$moment(this.form.project.dead_line).isBefore(date))
            ) {
                this.form.dead_line = this.$moment(
                    this.form.project.dead_line
                ).subtract({ hours: 1 });
            }
        },
        async afterVisibleChange(vis) {
            if (vis) {
                this.formInit();

                if (!this.edit) {
                    await this.generateForm(true);
                }

                this.draftWatch();
            } else {
                eventBus.$emit(
          `drawer_select_drop_temp_state_${
            this.form.id || this.defaultUserSelectId
          }`
                );

                this.$store.state.task.pageName = null;
                this.formInfo = null;
                formWatch();

                this.expandActive = 0;
                this.clearForm();
                if (this.back) {
                    this.back = false;
                    this.$store.commit("task/CHANGE_TASK_SHOW", true);
                }
            }
        },
        checkOpen() {
            if (this.$route.query?.createTask) {
                this.visible = true;
                let query = Object.assign({}, this.$route.query);
                delete query.createTask;
                this.$router.push({ query });
            }
        },
        reasonClear() {
            this.form.reason_model = null;
            this.form.reason_id = null;
        },
        close() {
            this.visible = false;
        },
        closeTaskSelected() {
            this.taskDrawer = false;
        },
        submitAndCopy() {
            this.formCopy = true;
            this.open = false;
            this.subtask = false;
            this.moreCreate = false;
            this.submit();
        },
        submitAndOpen() {
            this.open = true;
            this.formCopy = false;
            this.moreCreate = false;
            this.submit();
        },
        submitAndCreate() {
            this.moreCreate = true;
            this.open = false;
            this.subtask = false;
            this.formCopy = false;
            this.submit();
        },
        draftWatch() {
            formWatch = this.$watch("form", {
                deep: true,
                handler() {
                    if (this.visible && !this.edit && !this.copy && !this.subtask) {
                        this.isDraft = true;
                        localStorage.setItem(
                            "task_create_form_draft",
                            JSON.stringify(this.form)
                        );
                    }
                },
            });
        },
        async generateForm(vis) {
            if (!this.form.operator) {
                /*this.form.operator = {
                    ...this.user,
                    id: this.user.id,
                    full_name: this.user.last_name + ' ' + this.user.first_name
                }*/
            }

            this.form.owner = {
                ...this.user,
                id: this.user.id,
                full_name: this.user.last_name + " " + this.user.first_name,
            };

            if (
        this.formDefault?.task_type &&
        this.form.task_type !== this.formDefault["task_type"]
            )
                this.form.task_type = this.formDefault.task_type;

            if (this.isFromOrder()) {
                this.form.task_type = "logistic";
                const { data } = await this.$http(
                    "app_info/select_list/?model=catalogs.DeliveryPointModel"
                );
                this.startPointList = data.selectList;
            }

            if (this.form?.parent?.workgroup)
                this.form.workgroup = this.form.parent.workgroup;
            if (this.form?.parent?.project)
                this.form.project = this.form.parent.project;
            if (this.form?.parent?.dead_line)
                this.form.p_dead_line = this.form.parent.dead_line;

            const draftForm = JSON.parse(
                localStorage.getItem("task_create_form_draft")
            );
            if (draftForm && !this.edit && !this.copy && !this.disableDraft) {
                if (!isEqual(draftForm, this.form)) {
                    this.form = draftForm;
                    this.isDraft = true;
                }
            } else this.isDraft = false;

            if (this.formDefault?.project?.dead_line) {
                const dead_line = checkDateDeadLine(this.formDefault, "dead_line");
                this.formDefault.r_dead_line_from = dead_line;
                this.formDefault.p_dead_line_from = dead_line;
            }

            if (this.formDefault?.project?.date_start_plan) {
                this.formDefault.s_dead_line_from = checkDate(
                    this.formDefault,
                    "date_start_plan"
                );
            }

            if (this.formInject)
                this.form = Object.assign(this.form, this.formInject);
            if (this.formDefault)
                this.form = Object.assign(
                    this.form,
                    JSON.parse(JSON.stringify(this.formDefault))
                );

            if (vis) await this.getFormInfo();
        },
        async clearDraftForm() {
            formWatch();
            this.isDraft = false;
            localStorage.removeItem("task_create_form_draft");
            this.form = Object.assign({}, formModel);
            this.form.visors = [];
            this.fileList = [];
            this.$refs.taskForm.resetFields();
            await this.generateForm();
            this.draftWatch();
        },
        checkPvhValid() {
            if (this.$refs["pvh_widget"]?.$refs?.["pvh_form"]) {
                return this.$refs["pvh_widget"].$refs["pvh_form"].partsCheck();
            } else return true;
        },
        openSubtaskSelection() {
            if (this.changeParentDisabled) {
                return;
            }
            this.taskDrawer = true;
        },
        submit() {
            if (!this.loading) {
                this.$refs.taskForm.validate(async (valid, invalidFields) => {
                    this.invalidFields = Object.keys(invalidFields)
                    const pvhValid = this.checkPvhValid();
                    if (valid && pvhValid) {
                        try {
                            this.loading = true;
                            const res = await this.$store.dispatch("task/addTask", this.form);
                            if (res) {
                                if (res.project) {
                                    this.$store.commit("projects/ADD_TABLE_ROW", {
                                        record: res,
                                        tableKey: "project_tasks",
                                    });
                                }

                                const pvhForm = this.$refs["pvh_widget"]?.$refs?.["pvh_form"];
                                if (pvhForm) {
                                    await pvhForm.createForm({
                                        act: "save",
                                        posted: false,
                                        postedStatus: true,
                                        injectId: res.id,
                                    });
                                }

                                const openTask = () => {
                                    let query = Object.assign({}, this.$route.query);
                                    query.task = res.id;
                                    this.$router.push({ query });
                                };
                                // Временный фикс
                                if (this.mainKey && this.taskList?.[this.mainKey]?.length)
                                    eventBus.$emit("UPDATE_LIST");

                                if (this.open) {
                                    this.$message.info(this.$t("task.task_created"));
                                    openTask();
                                    this.open = false;
                                } else {
                                    this.$message.info(
                                        this.$createElement("span", {}, [
                      `${this.$t("task.task_created")}.`,
                      this.$createElement(
                          "span",
                          {
                              class: "link cursor-pointer blue_color",
                              on: {
                                  click: () => {
                                      openTask();
                                  },
                              },
                          },
                        ` ${this.$t("task.open_task")}`
                      ),
                                        ]),
                                        5
                                    );
                                }

                                if (!this.moreCreate && !this.formCopy) this.visible = false;

                                if (this.form.create_handler) {
                                    eventBus.$emit(
                    `TASK_CREATED_${this.form.task_type}_${this.form.create_handler}`,
                    {
                        ...res,
                        formData: this.form,
                    }
                                    );
                                }
                                eventBus.$emit(`drawer_select_save_state_${res.id}`);

                                eventBus.$emit(`TASK_CREATED_${this.form.task_type}`, {
                                    ...res,
                                    formData: this.form,
                                });
                                eventBus.$emit(`table_row_${this.pageName}`, {
                                    action: "create",
                                    row: res,
                                });

                                eventBus.$emit(`drawer_select_save_state_${this.form.id}`);

                                this.clearForm();
                                this.clearDraftForm();
                            }
                        } catch (error) {
                            console.log(error);
                            if (error?.length) this.$message.error(error.join(", "));
                            else this.$message.error(this.$t("task.error"));
                        } finally {
                            this.loading = false;
                        }
                    } else {
                        if (!pvhValid) this.expandActive = "2";
                        this.$message.warning(this.$t("task.field_require_all"));
                        return false;
                    }
                });
            }
        },
        update() {
            if (!this.loading) {
                this.$refs.taskForm.validate(async (valid) => {
                    const pvhValid = this.checkPvhValid();
                    if (valid && pvhValid) {
                        try {
                            this.loading = true;
                            const res = await this.$store.dispatch(
                                "task/updateTask",
                                this.form
                            );
                            if (res) {
                                if (res.project) {
                                    this.$store.commit("projects/UPDATE_TABLE_ROW", {
                                        record: res,
                                        tableKey: "project_tasks",
                                    });
                                }

                                const pvhForm = this.$refs["pvh_widget"]?.$refs?.["pvh_form"];
                                if (pvhForm) {
                                    await pvhForm.updateForm({
                                        act: "save",
                                        posted: false,
                                        postedStatus: true,
                                        injectId: res.id,
                                    });
                                }

                                this.$message.success(this.$t("task.task_updated"));
                                eventBus.$emit(`UPDATE_TEXT_VIEWER`);

                                eventBus.$emit(`table_row_${this.pageName}`, {
                                    action: "update",
                                    row: res,
                                });
                                this.visible = false;
                                this.clearForm();
                            }
                        } catch (error) {
                            console.log(error);
                            if (error?.length) this.$message.error(error.join(", "));
                            else this.$message.error(this.$t("task.error"));
                        } finally {
                            this.loading = false;
                        }
                    } else {
                        if (!pvhValid) this.expandActive = "2";
                        this.$message.warning(this.$t("task.field_require_all"));
                        return false;
                    }
                });
            }
        },
        clearForm() {
            this.isDraft = false;
            this.formInject = null;

            if (this.task && !this.back) this.$store.commit("task/SET_TASK", null);

            if (this.formDefault) this.$store.commit("task/SET_FORM_DEFAULT", null);

            if (!this.formCopy) {
                this.form = Object.assign({}, formModel);
                this.form.visors = [];
                this.fileList = [];
                this.$refs.taskForm.resetFields();
                this.generateForm();
            }

            this.formCopy = false;
            this.edit = false;
            this.copy = false;
            this.open = false;
            this.moreCreate = false;
        },
        updateForm({ key, data }) {
            this.back = false;
            this.formInject = {
                [key]: data,
            };
        },
        async setEdit({ back, task_type }) {
            this.edit = true;
            this.back = back;
            this.isDraft = false;

            let editForm = JSON.parse(JSON.stringify(this.task));

            if (task_type && this.form.task_type !== task_type) {
                editForm.task_type = task_type;
            }

            if (editForm.attachments.length) {
                editForm.attachments.forEach((file) => {
                    this.fileList.push({
                        uid: file.id,
                        name: file.path,
                        status: "done",
                        url: file.path,
                    });
                });
            }

            if (editForm.parent?.dead_line || editForm.project?.dead_line) {
                const dead_line = checkDateDeadLine(editForm, "dead_line");
                editForm.r_dead_line_from = dead_line;
                editForm.p_dead_line_from = dead_line;
            }

            if (editForm.parent?.date_start_plan || editForm.project?.date_start_plan)
                editForm.s_dead_line_from = checkDate(editForm, "date_start_plan");

            if (editForm.parent || editForm.attachments?.length)
                this.expandActive = 1;

            this.form = editForm;
            this.visible = true;
            this.getFormInfo();
        },
        openFileModal() {
            this.$nextTick(() => {
                this.$refs.fileAttach.openFileModal();
            });
        },
        formInit() {
            if (!this.form?.task_type) {
                this.form.task_type = this.taskType;
            }
        },
        isFromOrder() {
            return this.form.reason_model === "order";
        },
        async getParentById(id) {
            const params = {
                task_type: "task,stage,milestone",
                filters: { id: id },
            };

            return await this.$http.get("tasks/task/list", { params });
        },
    },
    mounted() {
        eventBus.$on("ADD_WATCH", async ({ type = null, data, key = null }) => {
            this.isDraft = false;
            if (key) {
                this.updateForm({ key, data });
                if (key === "parent" || key === "workgroup" || key === "project")
                    this.expandActive = 1;
            }
            if (type) {
                if (type === "subtask") {
                    this.subtask = true;
                    const subtask = {
                        parent: {
                            name: data.name,
                            id: data.id,
                            dead_line: data.dead_line,
                            date_start_plan: data.date_start_plan,
                        },
                        project: data.project || null,
                        workgroup: data.workgroup || null,
                        dead_line: data.dead_line ? this.$moment(data.dead_line) : null,
                        p_dead_line_from: checkDate(data, "dead_line"),
                        s_dead_line_from: checkDate(data, "date_start_plan"),
                        r_dead_line_from: checkDate(data, "dead_line"),
                    };
                    this.formInject = Object.assign({}, subtask);
                    this.expandActive = 1;
                } else if (type === "copy") {
                    this.copy = true;
                    let copy = { ...data };
                    copy.reason_model = "";
                    copy.reason_id = null;

                    if (copy.reason?.id) copy.reason = copy.reason.id;

                    copy.owner = {
                        ...this.user,
                        id: this.user.id,
                        full_name: this.user.last_name + " " + this.user.first_name,
                    };
                    if (copy.attachments?.length) {
                        copy.attachments.forEach((file) => {
                            this.fileList.push({
                                uid: file.id,
                                name: file.path,
                                status: "done",
                                url: file.path,
                            });
                        });
                    }
                    this.form = Object.assign({}, copy);
                    this.expandActive = 1;
                } else if (type === "add_task") {
                    let form = Object.assign({}, data);
                    if (data.attachments && data.attachments.length) {
                        data.attachments.forEach((file) => {
                            this.fileList.push({
                                uid: file.id,
                                name: file.path,
                                status: "done",
                                url: file.path,
                            });
                        });
                    }
                    form.p_dead_line_from = checkDate(data, "dead_line");
                    form.s_dead_line_from = checkDate(data, "date_start_plan");
                    form.r_dead_line_from = checkDate(data, "dead_line");

                    if (data.changeParentDisabled) {
                        this.changeParentDisabled = true;
                    }

                    if (data.parent && !data.parent.id) {
                        try {
                            const response = await this.getParentById(data.parent);
                            form.parent = response.data.results[0];
                        } catch (error) {
                            console.error(error);
                            form.parent = null;
                        }
                    }
                    // if

                    this.form = Object.assign(this.form, form);
                    this.disableDraft = true;
                    /*if(form.reason_model === 'order') {
                        this.form.task_type = 'logistic'
                    }*/

                    this.expandActive = 1;
                }
            }
            this.visible = true;
        });
        eventBus.$on("EDIT_TASK", ({ back, task_type }) => {
            this.setEdit({ back, task_type });
        });
    },
    beforeDestroy() {
        eventBus.$off("EDIT_TASK");
        eventBus.$off("ADD_WATCH");
    },
};
</script>

<style lang="scss">
.draft_task {
  position: absolute;
  top: 10px;
  right: 35px;
  border: 0px;
  .anticon {
    vertical-align: 0;
  }
}
.task_edit {
  &:not(.mobile_task_edit) {
    .d_body {
      padding: 30px;
    }
  }
  .auction_btn {
    height: 46px;
  }
  .ant-drawer-wrapper-body,
  .ant-drawer-content {
    overflow: hidden;
  }
  .ant-drawer-body {
    height: calc(100% - 40px);
    padding: 0px;
  }
  .ant-drawer-header {
    padding-left: 30px;
    padding-right: 30px;
  }
  .d_body {
    height: calc(100% - 40px);
    overflow-y: auto;
    overflow-x: hidden;
    .task_form_wrap {
      .ck-editor__editable {
        max-height: 300px;
      }
    }
  }
  .d_footer {
    display: flex;
    align-items: center;
    height: 40px;
    border-top: 1px solid #e8e8e8;
    padding-left: 30px;
    padding-right: 30px;
    .save_btn{
        margin-right: 5px;
    }
}
  .operator {
    .ant-form-item-children {
      display: flex;
    }
  }
}

.mobile_task_edit {
  .ant-drawer-header {
    padding-left: 15px;
    padding-right: 15px;
  }
  .d_body {
    height: calc(100% - 48px);
    .task_form_wrap {
      padding: 15px;
    }
  }
  .d_footer {
    padding-left: 15px;
    padding-right: 15px;
    height: 48px;
    .dots_btn {
      width: 55px;
    }
  }
}

.make_event {
  display: flex;
  align-items: center;
}

.tab-panel {
  padding: 15px;
  padding-top: 20px;
  padding-bottom: 25px;
  background-color: #fafafa;
  border: 1px solid #f1eeee;
}
</style>

<style lang="scss" scoped>
::v-deep {
  .collapse {
    border-top: 1px solid #f1eeee;
    border-radius: 0;
    background: transparent;
  }
  .ant-collapse-content-box {
    padding: 2px;
    padding-bottom: 30px;
  }
  .ant-collapse-item {
    border: 0;
  }
}

.button-light:not(.ant-btn-primary) {
    background: #F0F9FE;
    border: 1px solid #F0F9FE;
}
.button-gray {
    background: #1C65C01A;
}
.button-light.button_error,
.button-gray.button_error {
    border: 1px solid #f5222d;
    background-color: transparent;
    color: #f5222d;                             
}
</style>